
.zone{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    border-width: 5px;
    border-radius: 2px;
    /* border-color: ${props => getColor(props)}; */
    /* border-style: dashed; */
    /* border-width: 5px; */
    border-color: black;
    background-color: #ececec;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    height: 300px;
}
.zone:hover{
	/* border-style: none; */
    box-shadow: 2px 2px 10px rgba(7, 244, 90, 0.2);
}

.dropping{
    border-radius: 2px;
	width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-style: none; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
	background:rgb(255, 255, 255);
    transition: background-color .5s;
	cursor: pointer;
    text-align: center;
    border: 1px solid rgba(60, 60, 60, 0.248);
}
.zone:hover{
	/* border-style: none; */
    box-shadow: 0px 2px 2px rgba(60, 60, 60, 0.248);
}

