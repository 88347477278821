:root{
    --moderna-yellow:#ECAA20;
    --moderna-red:#d22630;
    --text-info-color:#7d7d7d;
    --moderna-gray:#F4F6F6;
}

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.15rem 0.5rem;
    margin: 0px;
}
.editing{
    box-shadow: 0px 0px 4px var(--moderna-yellow);

}

.box {
    background-color: var(--moderna-gray);
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}