:root{
    --moderna-yellow:#ECAA20;
    --moderna-red:#d22630;
    --text-info-color:#7d7d7d;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* @import url('https://fonts.googleapis.com/css?family=Abel|Aguafina+Script|Artifika|Athiti|Condiment|Dosis|Droid+Serif|Farsan|Gurajada|Josefin+Sans|Lato|Lora|Merriweather|Noto+Serif|Open+Sans+Condensed:300|Playfair+Display|Rasa|Sahitya|Share+Tech|Text+Me+One|Titillium+Web'); */

@font-face {
	font-family: 'Metropolis';
	src: url('./font/Metrophobic-Regular.ttf') format('truetype');
	/* Si descargaste el archivo en formato .otf, cambia 'truetype' a 'opentype' */
	font-weight: normal;
	font-style: normal;
}

/* Opcionalmente, puedes definir estilos para diferentes pesos o estilos de la fuente */
@font-face {
	font-family: 'Metropolis';
	src: url('./font/Metrophobic-Regular.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

body {
	font-family: 'Metropolis', sans-serif;
}

* {
	font-size: 16px;
}