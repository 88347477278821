:root{
    --moderna-gray:#F4F6F6;
}
.container {
    background-color: var(--moderna-gray);
    padding: 10px;
    margin-bottom: 10px;
}

.button{
    min-width: 10rem;
    width: 100%;
    margin: 0px;
    white-space: nowrap;
    text-align: center;
}

.boxItem {
    background-color: aquamarine;

}

.boxVariable {
    background-color:rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
}

.boxVariable2 {
    background-color:var(--moderna-gray);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    /* padding: 10px; */
}
.combobox{
    padding: 0.3rem 1rem;
}