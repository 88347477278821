.dropStyle {
  background-color: black;
}

.lab {
  /* background-color: red; */
  /* margin-right: 5px; */
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.inputDrop {
  background-color: rgb(217, 217, 217, 0.5);
  height: 40px;
  border-radius: 3px;
  color: rgb(71, 71, 71);
  font-size: 16px;
  padding-left: 14px;
}

.item {
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.errorText {
  position: absolute;
  font-size: 10px;
  /* background-color: greenyellow; */
  justify-content: baseline;
  display: flex;
  width: 100%;
  /* margin-bottom: 0%;*/
  margin-top: -8px;
  /* background-color: black; */
  color: rgb(255, 0, 0);
  /* padding-right: 5px; */
}

.box {
  margin-bottom: 10px;
}
