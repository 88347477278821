.container {
  /* display: flex; */
  background-size: cover;
  min-height: 100vh;
  /* width: 100%; */
  /* background-color: aquamarine; */
}

.button {
  width: 100%;
}

.box1 {
  background-image: url("https://modernaalimentos.com.ec/wp-content/uploads/slider/cache/73a622141dfc5a4b4234e114d32b3f93/fondo-1.jpg");
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxButton {
  position: fixed;
  top: 80px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  transform: translateY(0px);
  transition: 0.3s linear;
  background: transparent;
  border-radius: 0px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
  color: #ffffff;
  width: 80%;
  text-align: left;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #ffffff;
  font-size: 16px;
}

.btn:hover {
  background-color: #ec242c;
}

.btn:focus {
  background-color: #ec242c;
  box-shadow: none;
}
.active {
  background-color: #ec242c;
  box-shadow: none;
}
