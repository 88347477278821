.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  table-layout: fixed;
  white-space: nowrap;
}

.tableResponsive {
  margin-top: 10px;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.title {
  background-color: #d22630;
  color: #ffffff;
}

.buttonBox {
  display: flex;
  align-items: center;
}

.errorText {
  font-size: 10px;
  justify-content: end;
  display: flex;
  margin-bottom: 0%;
  margin-top: -8px;
  color: rgb(0, 255, 157);
  padding-right: 5px;
}

.errorText2 {
  font-size: 13px;
  /* background-color: greenyellow; */
  /* position: absolute; */
  /* background-color: black; */
  /* justify-content: baseline; */
  display: flex;
  height: 20px;
  width: 100%;
  text-align: center;
  justify-content: center;
  /* margin-bottom: 0%;*/
  /* margin-top: -8px;  */
  /* background-color: black; */
  color: rgb(255, 0, 0);
  margin-bottom: -18px;
  margin-top: -10px;

  /* padding-right: 5px; */
}

.comboPL {
  background-color: black;
  width: 600px;
}

.tableScroll {
  max-height: 300px; /* Altura máxima para habilitar el scroll vertical */
  overflow-y: auto;
}

.letter {
  text-transform: capitalize;
}
