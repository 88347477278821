.dropStyle{
    background-color: black;
}


.lab{
    /* background-color: red; */
    /* margin-right: 5px; */
    width: 100%;
    display: flex;
    padding-top: 6px;
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: black; */
}


.container{
    /* background-color: black; */
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorText{
    position: absolute;
    font-size: 10px;
    /* background-color: greenyellow; */
    justify-content: baseline;
    display: flex; 
    width: 100%;
    /* margin-bottom: 0%;*/
    /* margin-top: -8px;  */
    /* background-color: black; */
    color: rgb(255, 0, 0);
    /* padding-right: 5px; */
  }

  .box{
    margin-bottom: 20px;
  }