.button {
  min-width: 10rem;
  width: 100%;
  margin: 0px;
  white-space: nowrap;
  text-align: center;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  background-color: rgb(235, 235, 235);
}
.row {
  position: relative;
}
.fill {
  position: absolute;
  /* background-color: rgb(235, 235, 235); */
  width: 98%;
  height: 100%;
  z-index: -10;
}

/* .dropp { */
/* padding-left: 10px; */
/* } */
.poss {
  position: relative;
}
