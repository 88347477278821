.dropStyle{
    background-color: black;
}


.lab{
    /* background-color: red; */
    /* margin-right: 5px; */
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.inputDrop{
    background-color: rgb(217, 217, 217, 0.5);
    border-width: 0px;
}

/* .dateStyle{
    background-color: rgb(217, 217, 217, 0.5);
    border-radius: 5px;
    border-width: 0px;
    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;
} */

.dateInput{
   
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 41px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    /* background-color: #fff; */
    background-color: rgb(217, 217, 217, 0.5);
    /* margin: 8px 0px; */

}

.box{
    margin-bottom: 18px;
}

.errorText{
    position: absolute;
    font-size: 10px;
    /* background-color: greenyellow; */
    justify-content: baseline;
    display: flex; 
    width: 100%;
    /* margin-bottom: 0%;*/
    margin-top: 0px; 
    /* background-color: black; */
    color: rgb(255, 0, 0);
    /* margin-bottom: 5px; */
    /* padding-bottom: 5px; */
    /* padding-right: 5px; */
  }