.root{
    --cyan: #5BC2A7;
    --unselect:#7d7d7d;
    --disabled:#ffc6c6;
}

.container{
    display: flex;
    margin: 0px 2px;
}
.labelCbx {
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    background-color: #7d7d7d;
    border-radius: 5px;
}

.labelCbx input:checked+.checkbox {
    border-color: #5BC2A7;
}

.disabled input:checked+.checkbox {
    border-color: #7bb2a3;
}

.labelCbx input:checked+.checkbox svg path {
    fill: #5BC2A7;
}

.disabled input:checked+.checkbox svg path {
    filter: grayscale(0.7);
    /* filter: brightness(0.6); */
}

.labelCbx input:checked+.checkbox svg polyline {
    stroke-dashoffset: 0;
}

.labelCbx:hover .checkbox svg path {
    stroke-dashoffset: 0;    
}

.checkbox {
    position: relative;
    float: left;
    border: 2px solid #C8CCD4;
}

.checkbox svg {
    position: absolute;
    left: -2px;
    margin-top: -2px;
}

.checkbox svg path {
    fill: none;
    stroke: #5BC2A7;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 71px;
    stroke-dashoffset: 71px;
    transition: all .6s ease;    
}
.disabled svg path {
    stroke: #a2a2a2;
}
.disabled{
    cursor: default;
}
.checkbox svg polyline {
    fill: none;
    stroke: #FFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 18px;
    stroke-dashoffset: 18px;
    transition: all .3s ease;    

}

.labelCbx>span {
    pointer-events: none;
    vertical-align: middle;
}

.invisible {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;
}