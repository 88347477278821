.input:disabled{
    border: 0px;
    padding: 5px 4px 5px 7px;
    background-color: rgba(240, 248, 255, 0);
}
.input{
    padding: 4px 3px 4px 6px;
    border-radius: 5px;
}

.right{
    text-align: right;
}