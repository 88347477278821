:root{
    --bg:#0a1020;
    --dropdown-option-color:#0a1020;
    --dropdown-bg:#ffffff;
    --bg-accent:#484a4d;
    --text-color:#e9e9e9;
    --nav--size:60px;
    --border:1px solid #474a4d;;
    --border-radius:8px;
    --moderna-yellow:#ECAA20;
    --moderna-red:#d22630;

}
.item a{
    color: black;
}

.item a:hover{
    color: var(--moderna-red);
}
.active a{
    cursor: default;
    background-color: var(--moderna-red);    
    color: #e9e9e9;
    font-weight: bolder;
}
.active a:hover{
    background-color: var(--moderna-red);    
    color: #e9e9e9;
}
.active a:focus{
    background-color: var(--moderna-red);    
    color: #e9e9e9;
}