.inputBox {
  /* background-color: black; */
  padding: 0px 25px;
  /* margin: 10px 0px; */
  /* min-width: 500px; */
}

.imageBox {
  background-color: rgb(156, 156, 156);
}

.errorText {
  font-size: 14px;
  /* background-color: greenyellow; */
  justify-content: start;
  display: flex;
  margin-bottom: 8px;
  margin-top: -8px;
  color: red;
  padding-right: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(228, 30, 30, 0.548);
  justify-content: center;
}

.dropping:hover .overlay {
  opacity: 1;
}

.dropping {
  position: relative;
  width: 100%;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.button {
  min-width: 10rem;
  width: 100%;
  margin: 0px;
  white-space: nowrap;
  text-align: center;
}

.typeText {
  text-transform: capitalize;
}
