.icon {
  cursor: pointer;
}
.icon:hover {
  filter: brightness(1.1);
}
.icon:focus {
  filter: brightness(1.5);
}
.disabled {
  filter: brightness(0.8);
  cursor: default;
}

.disabled :hover {
  filter: brightness(0.8);
  cursor: default;
}
