:root{
    --moderna-yellow:#ECAA20;
    --moderna-red:#d22630;
    --row-color:#c7c7c7;
    --title-color:#fff;
}
.header{
    background-color: var(--moderna-red);
    color: var(--title-color);   
    z-index: 1000; 
}
.header:hover{
    filter: brightness(0.8);
}
.item{
    background-color: var(--row-color);
}
.row{
    padding: 0.3rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1px 0px;
}
.dropable{
    cursor: pointer;
}
.down{
    animation: rotation_down 0.2s linear;
    transform: rotate(90deg);
}
.up{
    animation: rotation 0.2s linear;
    transform: rotate(0deg);
}

@keyframes rotation_down {
    from {
      transform: rotateX(0deg);
    }
    to {
      transform: rotate(90deg);
    }
}
@keyframes rotation {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(0deg);
    }
}

.checkbox{
    position: relative;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    transition: background .1s ease;
    cursor: pointer;
    border-width: 0px;
    border-radius: 0px;
}

.checkbox:checked{
    animation: jelly .6s ease
}.icon_group{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}