.navTable {
  background-color: #d22630;
  /* background-color: COLORS.MODERNA_RED; */
  color: white;
  justify-content: center;
  justify-items: center;
  align-items: center;
  /* text-align: center; */
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  margin-top: 10px;
  padding: 3px 0px;
  padding-left: 25px;

  width: 100%;
  /* display: flex; */
}

.inputBox {
  /* padding: 0px 11px; */
  /* background-color: black; */
  width: 650px;
  display: flex;
  flex-direction: column;
}

.inputBox2 {
  padding: 0px 11px;
  /* background-color: black; */
  /* width: 650px; */
  display: flex;
  flex-direction: column;
}

.table {
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  display: flex;
  /* background-color: black; */
}

.container {
  /* display: flex;
  justify-content: center; */
  align-items: center;
  min-width: min-content;
  width: fit-content;
  max-width: 99vw;
}

.butt {
  /* position: absolute; */
  width: 100%;
  background-color: #d22630;
  border-width: 0px;
  border-radius: 0%;
}

.butt:hover {
  background-color: #ecaa20; /* O el color deseado */
  color: #ffffff; /* O el color deseado */
}

.butt:focus,
.butt:active {
  outline: none;
  box-shadow: none;
  background-color: #ecaa20; /* Reemplaza "your-color" con el color deseado */
  border-color: transparent; /* Reemplaza "your-color" con el color deseado */
}

.boxIm {
  height: 250px;
  padding: 0px 2px;
}

.button {
  color: rgb(255, 255, 255);
  background-color: #d22630;
  border-width: 0px;
  /* border-radius: 50%; */
}
.button:hover {
  background-color: #ecaa20; /* O el color deseado */
  color: #ffffff; /* O el color deseado */
  outline: none;
}
.button:focus,
.button:active {
  outline: none;
  box-shadow: none;
  background-color: #ecaa20; /* Reemplaza "your-color" con el color deseado */
  border-color: transparent; /* Reemplaza "your-color" con el color deseado */
}

.cajaImages {
  display: flex;
  flex: row;
  width: 650px;
  /* margin-bottom: 15px; */
  /* background-color: black; */
}

.errorText {
  font-size: 14px;
  /* position: absolute; */
  /* background-color: greenyellow; */
  justify-content: center;
  display: flex;
  /* margin-bottom: 0%; */
  /* margin-top: -8px; */
  color: red;
  /* padding-right: 5px; */
}

.container2 {
  /* background-color: black; */
  margin-bottom: 17px;
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
}

.inputstyle {
  border-radius: 3px;
  /* background-color: black; */
}

.typeText {
  text-transform: capitalize;
}
