.container{
    display: flex;
    justify-content: center;
    /* align-items: center;
    min-width: min-content;
    width: fit-content; 
    max-width: 99vw; */
}

.buttonGroup{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 1rem;
}

.button{
    margin-inline: 10px;
    background-color: aqua;
}