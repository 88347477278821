.navTable {
  background-color: #d22630;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 2px;
  /* margin-top: 10px; */
  width: 100%;
}

.navTableInactive {
  background-color: #88191f;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 2px;
  /* margin-top: 10px; */
  width: 100%;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  /* background-color: black; */
  /* flex: 1; */
  width: 100%;
  display: flex;
}

.nameExhibidorBox {
  /* flex: 1; */
  display: flex;
  justify-content: baseline;
  align-items: center;
  /* background-color:black; */
}

.caja {
  /* background-color: rgb(12, 250, 83); */
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  margin-top: 10px;
  /* background-color: black; */
  /* padding: 10px; */
}
