:root {
  --moderna-yellow: #ecaa20;
  --moderna-red: #d22630;
  --moderna-gray: rgb(235, 235, 235);
  --text-info-color: #7d7d7d;
}
.supercontainer {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0px;
  padding: 0px;
}

.right {
  display: flex;
  position: absolute;
  right: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  width: min-content;
  pointer-events: none;
}
.container {
  display: flex;
  min-width: 10rem;
  padding: 0px;
}
.label {
  color: var(--text-info-color);
  position: absolute;
  bottom: 100%;
  /* font-size: small; */
  left: -0.5rem;
}
.error {
  position: absolute;
  top: 76%;
  font-weight: 600;
  color: #d22630;
}

.capitalize {
  text-decoration: none;
}
.menu {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}
.open {
  margin-left: 0.5rem;
}

.primary {
  background-color: var(--moderna-gray);
  justify-content: space-between;
  color: black;
  border-radius: 2px;
  border: 1px solid #bebebe;
}
.dropdown {
  text-decoration: none;
  /* text-transform: uppercase; */
  color: rgb(87, 87, 87);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.7rem 1.6rem 0.7rem 0.5rem;
  position: relative;
}

.dropdown:hover {
  filter: brightness(0.8);
  color: black;
}

.scrollView {
  max-height: 200px;
  overflow-y: auto;
}

.errorText {
  position: absolute;
  font-size: 10px;
  /* background-color: greenyellow; */
  justify-content: baseline;
  display: flex;
  width: 100%;
  /* margin-bottom: 0%;*/
  /* margin-top: -8px;  */
  /* background-color: black; */
  color: rgb(255, 0, 0);
  /* padding-right: 5px; */
}

.cancelBtn {
  background-color: black;
  margin-right: 25px;
  color: #d22630;
}
