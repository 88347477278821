/* .containerModalAntd{
    background-color: blue;
}
.ModalMainAntd{
    background-color: aqua;
    z-index: 10;
} */
.modalStyle {
    border-radius: 20px;
   
  }
  
  .modalStyle .ant-modal-content {
    background-color: white;
    padding: 0px 0px 20px 0px!important;
  }

  .custom-close-icon {
    /* background-color: black; */
    font-size: 24px;
    /* width: 25px;
    height: 25px; */
    /* padding: 5px; */
    margin-top: 11px;
    color: white; /* Cambiar el color a tu preferencia */
  }
  
  
  .modalStyle .ant-modal-header {
    margin-top: 0!important;
    padding-left: 20px;
    background-color: #D22630;
    height: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
  
  }
  .ant-modal-header .ant-modal-title {
    font-size: 25px;
    color: white;
    font-weight:normal;
  }
  .containerModalAnt{
    padding: 10px 20px 10px 20px ;
  }