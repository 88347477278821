.navTable {
  background-color: #d22630;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 0px;
  margin-top: 10px;
  width: 100%;
}

.customcheckbox .form-check-input {
  background-color: #f2f2f2; /* Cambia el color de fondo según tus necesidades */
  border-color: #ff0000; /* Cambia el color de borde según tus necesidades */
}

.customcheckbox .form-check-input:checked {
  background-color: #ff0000; /* Cambia el color de fondo cuando está seleccionado */
  border-color: #000000; /* Cambia el color de borde cuando está seleccionado */
}

.box {
  background-color: transparent;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  /* display: flex; */
}

.inputBox {
  /* background-color: black; */
  padding: 0px 25px;
  margin: 10px 0px;
}

.inputWithIcon {
  /* background-color: black; */

  position: relative;
  display: inline-block;
  text-align: right;
}

.inputWithIcon .icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Ajusta la posición del ícono según tus necesidades */
  transform: translateY(-50%);
}
.search {
  justify-content: flex-end;
}
.searchBox {
  width: 400px;
  height: 30px;
  border-radius: 7px;
  text-align: center;
  border-color: black;
  border-width: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.container {
  padding-left: 0px;
  padding-right: 0px;
}
.backColor {
  background-color: var(--moderna-red);
  color: white;
}
.rowMargin {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  /* background-color: black; */
}

.check1 {
  /* width: 20px;
  height: 20px;
  padding-top: 7px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}
.check {
  width: 20px;
  height: 20px;
  padding-top: 7px;
  background-color: var(--moderna-red);
  color: white;
}

.column {
  width: 20px;
}
.navContent {
  background-color: rgb(254, 254, 254);
  /* color: white; */
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.caja {
  align-items: center;
  text-align: center;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 5px;
}

/* _______________________________________________ */
.customCheckbox {
  display: none;
}

.customCheckbox + label {
  position: relative;
  /* padding-left: 25px; */
  cursor: pointer;
}

.customCheckbox + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #ffffff;
  /* padding-top: 5px; */
  /* margin-top: 2px; */
}

.customCheckbox:checked + label::before {
  background-color: #00ae9e;
}

.checkboxIcon {
  position: absolute;
  top: -4px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  left: 2px;
  width: 14px;
  height: 14px;
  display: none;
}

.customCheckbox:checked + label .checkboxIcon {
  display: block;
}

.customCheckbox + label i {
  color: white;
}

.cus2:checked + .cus2label::before {
  background-color: red;
  border-color: red;
}

.cus2:checked + .cus2label::after {
  border-color: white;
}

.cus2:checked + label::before {
  background-color: #00ae9e;
}

.button {
  min-width: 10rem;
  width: 100%;
  margin: 0px;
  white-space: nowrap;
  text-align: center;
  height: 39px;
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
}
.label {
  width: 100%;
}
.dropdownMod {
  width: 100px;
}
.dropStyle {
  background-color: black;
}
.lab {
  /* background-color: red; */
  /* margin-right: 5px; */
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.container {
  /* background-color: black; */
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable {
  overflow-x: scroll;
}

/* Tamaño de letra para pantallas grandes */
@media (min-width: 1200px) {
  .large {
    font-size: 16px;
  }
}

/* Tamaño de letra para pantallas medianas */
@media (max-width: 1199px) {
  .medium {
    font-size: 12px;
  }
}

/* Tamaño de letra para pantallas pequeñas */
@media (max-width: 767px) {
  .small {
    font-size: 7px;
  }
}
@media (max-width: 575px) {
  .button_col {
    margin: 0.7rem 0rem;
  }
}
.title {
  background-color: var(--moderna-red);
  text-align: center;
  color: #ffffff;
}
.tabla_columna {
  margin-left: "0px";
  margin-right: "0px";
  text-align: center;
}

.tabla {
  width: 100%;
  background-color: var(--moderna-red);
}

.back {
  background-color: #d9d9d9;
}

.select {
  background-color: var(--moderna-red);
  color: #ffffff;
}

.tableT {
  background-color: #ffffff;
}

.widthDrop {
  height: 39px;
  /* padding-left: 40px; */
  /* justify-content: space-between; */
}
