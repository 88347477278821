:root{
    --bg:#0a1020;
    --dropdown-option-color:#0a1020;
    --dropdown-bg:#ffffff;
    --bg-accent:#484a4d;
    --text-color:#e9e9e9;
    --nav--size:60px;
    --border:1px solid #474a4d;;
    --border-radius:8px;
    --moderna-yellow:#ECAA20;
    --moderna-red:#d22630;

}


.dropdown{
    position: absolute;
    top: var(---nav--size);
    width: max-content;
    background-color: var(--dropdown-bg);
    /* border: var(--border); */
    padding: 0rem 0.5rem;
    overflow: hidden;
    box-shadow: 
     1px 0px 5px -5px rgba(0, 0, 0, 0.75),
    -1px 0px 5px -5px rgba(0, 0, 0, 0.75),
    0px 5px 5px -5px rgba(0, 0, 0, 0.75);
}

.menuItem{
    text-decoration:none;
    color: var(--dropdown-option-color);
    height: max-content;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem 0.1rem;
    cursor: pointer;
}

.menuItem:hover{
    filter: brightness(1.2);
    color: var(--moderna-yellow);
}
.menuItem:focus{
    color: var(--moderna-red);
}

.active{
    color: var(--moderna-yellow);
    border-bottom:3px solid var(--moderna-yellow);
}
.active:hover{
    color: var(--moderna-yellow);
}
.active:focus{
    color: var(--moderna-yellow);
}

.activeOption{
    color: var(--moderna-yellow);
    cursor: default;
}
.activeOption:focus{
    color: var(--moderna-yellow);
}

.navbar{
    background-color: black;
}