.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    padding: 16px;
}
.spinning{
    margin: 10% auto 0px;
    animation: spinning 1s linear infinite;
    color: rgb(206, 26, 26);
}
.spinner .text{
    text-decoration: none;
    color: rgb(36, 36, 36);
    margin: 15px auto 0px;
    font-size: x-large;
    font-weight: 1000;
}
.dark svg {
    color: rgb(142, 142, 142);
}
.dark .text {
    color: rgb(206, 206, 206);
    text-shadow: 1px 2px 1.5px rgb(107, 107, 107);
    text-decoration: none;
}

@keyframes spinning {
    to{
        transform: rotate(360deg);
    }
}

.layout{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.233);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
  } */