.container {
  margin: 50px;
  padding: 0;
  font-family: 'Tomorrow', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  top: 10%;
  text-align: center;
}

.h1 {
  font-size: 50px;
}

.astronaut_img {
  width: 100px;
  position: absolute;
  top: 55%;
  animation: astronautFly 6s infinite linear;
}

@keyframes astronautFly {
  0% {
    left: -100px;
  }

  25% {
    top: 50%;
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(45deg);
    top: 55%;
  }

  75% {
    top: 60%;
    transform: rotate(30deg);
  }

  100% {
    left: 110%;
    transform: rotate(45deg);
  }
}

.btnRep {
  /* background-color: black; */
  border-radius: 25px;
}