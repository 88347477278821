.container {
    justify-content: center;
    margin-bottom: 20px;
    /* background-color: black; */
}
.center{
    text-align: center;
}

/* .inputstyle {
    padding: 2px 10px;
    border-radius: 5px;
    border: 0px;
    background-color: rgb(217, 217, 217, 0.5);

    width: 100%;
    height: 35px;
    margin-bottom: 10px ;
} */

.lab {
    /* background-color: rgb(231, 222, 222); */
    /* padding-right: 25px; */
    width: 100%;
    white-space: nowrap;
    display:flex;
    align-items: center;
    /* background-color: rgb(12, 250, 171); */
}

.item{
    /* background-color: rgb(124, 118, 118); */
    /* margin-bottom: 2px; */
    /* background-color: rgb(223, 22, 22); */
    /* display: flex; */
    justify-content: center;
    /* align-items: center; */
}

.errorText{
    font-size: 10px;
    /* background-color: greenyellow; */
    position: absolute;
    justify-content: baseline;
    display: flex; 
    width: 100%;
    /* margin-bottom: 0%;*/
    /* margin-top: -8px;  */
    /* background-color: black; */
    color: rgb(255, 0, 0);
    
    /* padding-right: 5px; */
  }

  .inputstyle{
    border-radius: 3px;
    /* text-transform: capitalize; */
    /* background-color: black; */
  }