:root {
  --moderna-yellow: #ecaa20;
  --moderna-red: #d22630;
  --moderna-gray: rgb(235, 235, 235);
  --moderna-turqey: #00ae9e;
  --text-info-color: #7d7d7d;
}
.supercontainer {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0px;
  padding: 0px;
}

.right {
  display: flex;
  position: absolute;
  right: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  width: min-content;
  pointer-events: none;
}
.container {
  display: flex;
  min-width: 10rem;
  padding: 0px;
  position: relative;
}
.label {
  color: var(--text-info-color);
  position: absolute;
  bottom: 100%;
  font-size: small;
  left: -0.5rem;
}
.error {
  position: absolute;
  top: 76%;
  font-weight: 600;
  color: #d22630;
}
.dropdown {
  text-decoration: none;
  /* text-transform: uppercase; */
  color: black;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.7rem 1.6rem 0.7rem 0.5rem;
  position: relative;
}
.capitalize {
  text-decoration: none;
}
.menu {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  text-transform: capitalize;
}
.open {
  margin-left: 0.5rem;
}
.gray {
  background-color: var(--moderna-gray);
  justify-content: flex-start;
  color: black;
  padding-left: 40px;
}
.secondary {
  background-color: rgb(235, 235, 235);
  /* justify-content: center; */
  color: black;
}

.primary {
  background-color: var(--moderna-red);
  /* justify-content: space-evenly; */
  color: white;
  align-items: center;
  height: 50px;
}

.dropdown:hover {
  filter: brightness(0.8);
  color: black;
}

.scrollView {
  max-height: 200px;
  overflow-y: auto;
}

.clear:hover {
  color: white;
  filter: brightness(1.5);
}
