.imageBox {
  display: flex;
  /* width: 450px; */
  width: 100%;
  /* padding: 2px; */
  margin-bottom: 10px;
}

.imageBox img {
  height: 300px; /* Ajusta la altura deseada */

  /* object-fit: cover; */
  object-position: center;
  flex-grow: 1;
}

.carouselIcon {
  font-size: 30px; /* Tamaño de la fuente */
  color: red; /* Cambia esto al color que desees */
  cursor: pointer; /* Cambia el cursor al estilo de puntero */
}
