.container{
	padding: 2.8rem 3.8rem;	
	min-height: 100vh;
}
.box{
	
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.left img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.left{
	min-height: calc(100vh - 2.8rem*2);
	background-color: #EA272A;
	padding: 0px;
}
.left_bottom{
	padding: 2rem 1rem 0rem 1rem;
	text-align: center;
}

.right{
	text-align: center;
}

.right h3{
	margin: 2rem 0rem 4rem 0rem;
	font-size: x-large;
}
.right h1{
	margin: 6rem 0rem 10rem 0rem;
	font-size: 56px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	text-transform: uppercase;
}
.right h2{
	margin: 12rem 0rem 0rem 0rem;
	font-size: 35px;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
	text-transform: uppercase;
	text-align: start;
	width: 100%;
}
.button{
	margin: 0rem 0rem 2rem 0rem;
	min-width: 12rem;
    white-space: nowrap;
    text-align: center;
}
@media (max-width: 576px){
	.container{
		padding: 0.7rem 1.5rem ;
	}
	.right h3{
		margin: 2rem 0rem 2rem 0rem;
	}
	.right h1{
		margin: 2rem 0rem 2rem 0rem;
		text-align: center;
		word-wrap: break-word;
	}.right h2{
		margin: 2rem 0rem 1rem 0rem;
	}
}
@media (min-width: 577px) and (max-width: 890px){
	.right h1{
		text-align: left;
		margin: 6rem 0rem 5rem 0rem;
		word-wrap: break-word;
	}
}
