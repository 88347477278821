.navContent {
  background-color: rgb(20, 7, 7);
  /* color: white; */
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  display: flex;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.caja {
  align-items: center;
  text-align: center;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
}

.colSpecial1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
}

.colSpecial2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.editInput {
  width: 2rem;
  border: "none";
  margin-top: 5px;
  text-align: end;
}

.content {
  margin-top: 10px;
  font-weight: 600;
}

.contentCombo {
  margin-left: 100px;
}

.inputBox {
  /* background-color: black; */
  padding: 0px 25px;
  margin: 10px 0px;
}
.navContent {
  background-color: rgb(254, 254, 254);
  /* color: white; */
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  display: flex;
}

.button {
  min-width: 10rem;
  width: 100%;
  margin: 0px;
  white-space: nowrap;
  text-align: center;
}
/* .buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */

/* .caja {
  align-items: center;
  text-align: center;
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
} */

/* .colSpecial1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
} */

/* .colSpecial2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
} */

.editInput {
  width: 2rem;
  border: "none";
  margin-top: 5px;
}

.iconPercentage {
  color: var(--moderna-red);
}
.Padding {
  padding-left: 4px;
  padding-right: 0px;
}
.marginRow {
  padding-top: 5px;
  text-align: start;
  text-transform: capitalize;
}

.scroll-container {
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
  white-space: nowrap; /* Evita que los elementos se envuelvan */
}

.navContentP {
  background-color: var(--moderna-red);
  color: white;
  text-align: center;
  width: 100%;
  display: flex;
  margin-left: "5px";
  margin-right: "5px";
  margin-bottom: "5px";
}
/* .letter {
  background-color: black;
  font-size: 50px;
  font-weight: 50px;
} */

/* .rowContent1 {
  background-color: #e2e2e2;
  padding-bottom: 100px;
  margin-bottom: 50px;
  padding-left: 0px;
  padding-right: 0px;
} */
.content1 {
  margin-top: 10px;
  font-weight: 600;
  background-color: black;
}

.myConfirmButton {
  opacity: 1.7;
  /* background-color: black; */
  /* color: black; */
}

.boxArrow {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  /* background-color: "black"; */
}
