.navContent {
  background-color: #f4f6f6;
  justify-content: center;
  justify-items: center;
  align-items: center;
  /* text-align: center; */
  margin-top: 2px;
  padding: 5px 0px;
  width: 100%;
  padding-left: 25px;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  color: white;
}

.box {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
