.btn {
	transform: translateY(0px);
	transition: 0.3s linear;
	/* background: #02ff95; */
	border-radius: 2px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	outline: none;
	border: none;
	color: #ffffff;
	padding: 0.7rem ;
	/* padding: 0.7rem 2rem; */
	min-width: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn:hover {
    filter: brightness(1.2);
}

.icon{
	color: #ffffff;
	/* background-color: black; */
	/* padding-bottom: 5x; */
	margin-top: 2px;
	height: 20px;
	width: 20px;
	/* padding-bottom: 1px; */
}

.cicon{
	/* background-color: black; */
	display: flex;
	justify-content: center;
	align-items: center;
	/* padding-bottom: 10px; */
}