.spinner{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.spinning{
    margin: 10% auto 0px;
    animation: spinning 1s linear infinite;
}
.spinner .text{
    text-decoration: none;
    color: rgb(36, 36, 36);
    margin: 15px auto 0px;
    font-size: x-large;
    font-weight: 1000;
}
.dark svg {
    color: rgb(142, 142, 142);
}
.dark .text {
    color: rgb(206, 206, 206);
    text-shadow: 1px 2px 1.5px rgb(107, 107, 107);
    text-decoration: none;
}
.clean{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spinning {
    to{
        transform: rotate(360deg);
    }
}
